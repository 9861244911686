import React from 'react';
import Layout from '../components/layout';

export default function FAQ() {
  return (
    <Layout>
      <div>FAQ</div>
    </Layout>
  );
}
